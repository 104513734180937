import React from 'react';

import { twMerge } from 'tailwind-merge';

import { SocialLinkResponse } from '@bloom/codegen/models/SocialLinkResponse';

import withTemplate from '@bloom/library/components/HOC/withTemplate';

import SocialLink from './SocialLink';

import style from './SocialLinks.module.scss';

interface IProps {
  activeTemplate: string;
  className?: string;
  isLargeIcons: boolean;
  links: Array<SocialLinkResponse>;
  showInTwoRows: boolean;
}

const SocialLinks: React.FC<IProps> = (props) => {
  const { activeTemplate = 'verticalSplit', className, isLargeIcons, links, showInTwoRows } = props;

  const isTwoRows = showInTwoRows && links.length > 4;
  return (
    <nav className={twMerge(style.links, isTwoRows ? style.twoRowsView : '', className)}>
      <div className={style.wrapper}>
        {links.map((link, index) => (
          <SocialLink
            isLarge={isLargeIcons || activeTemplate === 'minimalist'}
            key={index}
            link={link}
          />
        ))}
      </div>
      {isTwoRows && (
        <div className={style.table}>
          <div className={style.row}>
            {links.slice(0, 4).map((link, index) => (
              <div className={style.col} key={index}>
                <SocialLink
                  isLarge={isLargeIcons || activeTemplate === 'minimalist'}
                  key={index}
                  link={link}
                />
              </div>
            ))}
          </div>
          <div className={style.row}>
            {links.slice(4).map((link, index) => (
              <div className={style.col} key={index}>
                <SocialLink
                  isLarge={isLargeIcons || activeTemplate === 'minimalist'}
                  key={index}
                  link={link}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default withTemplate(SocialLinks);
