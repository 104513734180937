import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const TumblrIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M9.791 14c-1.808 0-3.149-.927-3.149-3.149V7.296H5V5.367C6.808 4.9 7.561 3.348 7.644 2H9.52v3.058h2.184v2.238H9.52v3.096c0 .926.467 1.25 1.213 1.25h1.062V14H9.79Z"
        fill={color}
      />
    </svg>
  );
};

export { TumblrIcon };
