'use client';
import React, { useEffect, useRef } from 'react';

import { Modal, ModalSizeEnum } from '@bloom/ui/components/Modal';

import { useAnswerGroup } from '@bloom/library/components/hooks/useAnswerGroup';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import QuoteRequest from '@bloom/library/components/QuoteRequest/QuoteRequest';
import { isUrl } from '@bloom/library/utils/misc';

interface IProps extends Omit<React.ComponentProps<typeof QuoteRequest>, 'onClose' | 'questions'> {
  redirectUrl?: string;
}

const QuoteRequestModal: React.FC<IProps> = (props) => {
  const {
    documentObj = typeof document === 'undefined' ? undefined : document,
    redirectUrl = '',
    windowObj = typeof window === 'undefined' ? undefined : window,
  } = props;

  const [{ questionnaire }] = useQuoteRequest();

  const [quoteRequest, { resetState, setAnswerGroupId, showView }] = useQuoteRequest();
  const { isShown } = quoteRequest.modal;

  const questionnaireIdRef = useRef('');

  const isShownRef = useRef(false);

  const answerGroupIdRef = useRef('');

  useEffect(() => {
    answerGroupIdRef.current = quoteRequest.modal.answerGroupId || '';
  }, [quoteRequest.modal.answerGroupId]);

  useEffect(() => {
    const { introText, introTitle } = questionnaire;
    // Decide if intro view should be shown.
    if (
      (!isShownRef.current && quoteRequest.modal.isShown) ||
      (!questionnaireIdRef.current && questionnaire.id)
    ) {
      if (introTitle && introText) {
        showView(QuoteRequestScreenEnum.INTRO);
      }
    }
  }, [questionnaire, quoteRequest.modal.isShown, showView]);

  const { answerGroup, fetchAnswerGroup } = useAnswerGroup(questionnaire.id);

  useEffect(() => {
    if (!quoteRequest.modal.answerGroupId && answerGroup?.id) {
      setAnswerGroupId(answerGroup.id);
    }
  }, [answerGroup?.id, quoteRequest.modal.answerGroupId, setAnswerGroupId]);

  useEffect(() => {
    if (answerGroupIdRef.current && !quoteRequest.modal.answerGroupId) {
      fetchAnswerGroup({ resourceId: '', resourceType: '' });
    }
  }, [quoteRequest.modal.answerGroupId, fetchAnswerGroup]);

  useEffect(() => {
    // Update answerGroupId and page title if a new form is shown.
    if (
      // If we open the modal and questionnaire is already fetched.
      (!isShownRef.current && quoteRequest.modal.isShown && questionnaire.id) ||
      // If modal is already open or will be open on the next render,
      // and questionnaire has just got fetched.
      (quoteRequest.modal.isShown && !questionnaireIdRef.current && questionnaire.id)
    ) {
      // Fetch answer group id.
      let body;

      const urlParams = new URLSearchParams(window.location.search);

      const resourceId = urlParams.get('id');
      const resourceType = urlParams.get('t')?.toUpperCase();

      if (resourceId && resourceType) {
        body = {
          resourceId,
          resourceType,
        };
      }

      fetchAnswerGroup(body).then((res) => {
        if (
          res.status === AsyncStatusEnum.SUCCESS &&
          res.data['answer-group']?.id &&
          quoteRequest.modal.answerGroupId !== res.data['answer-group'].id
        ) {
          setAnswerGroupId(res.data['answer-group'].id);
        }
      });

      document.title = (questionnaire.title || '').replace('&amp;', '&');
    }
  }, [
    fetchAnswerGroup,
    questionnaire.id,
    questionnaire.title,
    quoteRequest.modal.answerGroupId,
    quoteRequest.modal.isShown,
    setAnswerGroupId,
  ]);

  function handleClose() {
    const { introText, introTitle } = questionnaire;
    const { currentView } = quoteRequest.modal;

    // Don't show confirm close view if it's final screen or
    // user hasn't entered any input.
    if (
      ![
        QuoteRequestScreenEnum.INTRO,
        QuoteRequestScreenEnum.OUTRO,
        QuoteRequestScreenEnum.SUMMARY,
        QuoteRequestScreenEnum.CONFIRM_CLOSE,
      ].includes(currentView)
    ) {
      showView(QuoteRequestScreenEnum.CONFIRM_CLOSE);
      return;
    }

    resetState();

    if (questionnaire.redirectUrl && isUrl(questionnaire.redirectUrl)) {
      window.location = questionnaire.redirectUrl;
      return;
    }

    if (redirectUrl) {
      window.location = redirectUrl;
      return;
    }

    if (introTitle && introText) {
      showView(QuoteRequestScreenEnum.INTRO);
    } else {
      showView(QuoteRequestScreenEnum.QUESTION);
    }
  }

  // ! These hooks must be the last in the list of hooks.
  // React relies on the order in which Hooks are called
  // https://reactjs.org/docs/hooks-rules.html
  // So we should update the references as the last thing on component render
  useEffect(() => {
    isShownRef.current = quoteRequest.modal.isShown;
  }, [quoteRequest.modal.isShown]);

  useEffect(() => {
    questionnaireIdRef.current = questionnaire.id;
  }, [questionnaire]);

  return (
    <Modal
      className="p-0"
      closeOnEsc={false}
      data-mode={questionnaire.settings.colorTheme}
      onClose={handleClose}
      open={isShown}
      root={documentObj?.body}
      size={ModalSizeEnum.FULLSCREEN}
    >
      <QuoteRequest documentObj={documentObj} onClose={handleClose} windowObj={windowObj} />
    </Modal>
  );
};

export default QuoteRequestModal;
