import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { QuestionnaireResponse } from '@bloom/codegen/models/QuestionnaireResponse';

import { SharedQueryKeyEnum } from './interface';

export async function fetchPublicQuestionnaire(
  accountId: string,
  formId = ''
): Promise<QuestionnaireResponse | null> {
  try {
    const response = await fetch(
      `${process.env.BLOOM_API}/api/public-forms/${accountId}/forms/${formId}`,
      {
        headers: { Accept: 'application/vnd.bloom.v3', 'Content-Type': 'application/json' },
        method: 'GET',
        mode: 'cors',
      }
    );

    if (response.ok) {
      const data: { questionnaire: QuestionnaireResponse } = await response.json();
      return data.questionnaire;
    }
  } catch (error) {
    console.log('🚨 ~ error:', error);
  }

  return null;
}

export function usePublicQuestionnaire(accountId: string, formId = '') {
  const { data: questionnaire, isFetching } = useQuery({
    enabled: !!accountId && !!formId,
    queryFn: () => fetchPublicQuestionnaire(accountId, formId),
    queryKey: [SharedQueryKeyEnum.PUBLIC_QUESTIONNAIRE, accountId, formId],
  });

  return useMemo(() => ({ isFetching, questionnaire }), [isFetching, questionnaire]);
}
