import React from 'react';

import { twMerge } from 'tailwind-merge';

import PrimaryButton from './PrimaryButton';

import style from './SecondaryButton.module.scss';
type TProps = React.ComponentProps<typeof PrimaryButton> & {
  isDarkBackground?: boolean;
};
const SecondaryButton: React.FC<TProps> = ({
  className = '',
  isDarkBackground = false,
  ...restProps
}) => (
  <PrimaryButton
    {...restProps}
    className={twMerge(isDarkBackground ? style.darkBackground : '', className)}
    isSecondary
  />
);

export default SecondaryButton;
