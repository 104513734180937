'use client';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import { Modal } from '@bloom/ui/components/Modal';

import withTemplate from '@bloom/library/components/HOC/withTemplate';
import ArrowLeftIcon from '@bloom/library/components/Icon/ArrowLeft';
import ArrowLeftLightIcon from '@bloom/library/components/Icon/ArrowLeftLight';
import ArrowRightIcon from '@bloom/library/components/Icon/ArrowRight';
import ArrowRightLightIcon from '@bloom/library/components/Icon/ArrowRightLight';

import { SLIDESHOW } from '../constants';
import Slide from './Slide';
import Thumbnails from './Thumbnails';

import style from './Slideshow.module.scss';

class Slideshow extends Component {
  static propTypes = {
    activeTemplate: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    colorTheme: PropTypes.string,
    images: PropTypes.array.isRequired,
    isArrowsShown: PropTypes.bool,
    isCloseButtonShown: PropTypes.bool,
    isExpanded: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSlideChange: PropTypes.func,
    selectedIndex: PropTypes.number,
    topBarClassName: PropTypes.string,
  };

  static defaultProps = {
    activeTemplate: 'nordic-air',
    children: null,
    className: '',
    colorTheme: 'LIGHT',
    isArrowsShown: true,
    isCloseButtonShown: true,
    onSlideChange: undefined,
    selectedIndex: undefined,
    topBarClassName: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: props.selectedIndex || 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedIndex } = this.props;

    if (prevProps.selectedIndex !== selectedIndex) {
      this.setState((state) => (state.selectedIndex !== state ? { selectedIndex } : null));
    }
  }

  setSelectedIndex = (index) => {
    const { images, onSlideChange } = this.props;
    const selectedIndex = Math.max(0, Math.min(index, images.length - 1));
    this.setState({ selectedIndex });
    if (typeof onSlideChange === 'function') {
      const image = images[selectedIndex];
      onSlideChange(image.id);
    }
  };

  render() {
    const {
      activeTemplate,
      children,
      className,
      colorTheme,
      isCloseButtonShown,
      isExpanded,
      onClose,
      topBarClassName,
      ...restProps
    } = this.props;
    const { selectedIndex } = this.state;

    return (
      <Modal
        className={twMerge(
          style.lightbox,
          activeTemplate === 'minimalist' ? style.minimalistTemplate : '',
          activeTemplate === 'nordic-air' ? style.nordicAirTemplate : '',
          colorTheme === 'DARK' ? style.darkTheme : '',
          className
        )}
        onClose={onClose}
        open={isExpanded}
        size="fullscreen"
        withCloseButton={isCloseButtonShown}
      >
        {React.Children.map(children, (child, index) =>
          index === 0 ? (
            <div className={twMerge(style.topBar, topBarClassName)}>
              <div className={style.buttons}>{child}</div>
            </div>
          ) : (
            child
          )
        )}
        <Slide
          {...restProps}
          gridType={SLIDESHOW}
          isCover={false}
          onIndexChange={this.setSelectedIndex}
          selectedIndex={selectedIndex}
        />
        <div
          className={twMerge(
            style.prev,
            selectedIndex === 0 ? style.isDisabled : '',
            activeTemplate === 'minimalist' ? style.minimalistTemplateIcon : '',
            activeTemplate === 'nordic-air' ? style.nordicAirTemplateIcon : ''
          )}
          onClick={this.setSelectedIndex.bind(null, selectedIndex - 1)}
        >
          {this.props.isArrowsShown ? (
            activeTemplate === 'minimalist' || activeTemplate === 'nordic-air' ? (
              <ArrowLeftLightIcon width={40} />
            ) : (
              <ArrowLeftIcon />
            )
          ) : null}
        </div>
        <div
          className={twMerge(
            style.next,
            selectedIndex >= this.props.images.length - 1 ? style.isDisabled : '',
            activeTemplate === 'minimalist' ? style.minimalistTemplateIcon : '',
            activeTemplate === 'nordic-air' ? style.nordicAirTemplateIcon : ''
          )}
          onClick={this.setSelectedIndex.bind(null, selectedIndex + 1)}
        >
          {this.props.isArrowsShown ? (
            activeTemplate === 'minimalist' || activeTemplate === 'nordic-air' ? (
              <ArrowRightLightIcon width={40} />
            ) : (
              <ArrowRightIcon />
            )
          ) : null}
        </div>
        <Thumbnails
          {...restProps}
          className={style.slideshowThumbnails}
          onSelect={this.setSelectedIndex}
          selectedIndex={selectedIndex}
        />
      </Modal>
    );
  }
}

export default withTemplate(Slideshow);
