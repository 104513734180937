import React from 'react';

import { twMerge } from 'tailwind-merge';

import Loader from '@bloom/library/components/Loader';

import Button from './Button';

import style from './PrimaryButton.module.scss';

type TProps = React.HTMLProps<HTMLButtonElement> & {
  customColor?: string;
  isDisabled?: boolean;

  isLoading?: boolean;
  isSecondary?: boolean;

  isUploading?: boolean;

  styles?: React.CSSProperties;
};

const PrimaryButtonPure: React.FC<TProps> = (props) => {
  const {
    children,
    className,
    customColor = '#ff325a', // default picr color
    isDisabled,
    isLoading,
    isSecondary,
    isUploading,
    onClick,
    styles,
    ...restProps
  } = props;

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (isDisabled || isLoading || isUploading) {
      e.preventDefault();
      return;
    }
    if (typeof onClick === 'function') {
      onClick(e);
    }
  }

  const buttonClasses = twMerge(
    style.button,
    isLoading ? style.loading : '',
    isUploading ? style.uploading : '',
    isDisabled ? style.disabled : '',
    isSecondary ? style.secondary : style.primary,
    className
  );

  // allow to add custom inline styles to a primaty button
  // currently it is useful for Messenger Widget primary actions
  // see comments on <PrimaryButton in components/MessengerWidge/GuestForm.js
  const buttonStyles = {
    ...styles,
    ...(!isSecondary ? { backgroundColor: customColor } : {}),
  };

  return (
    <Button
      {...restProps}
      className={buttonClasses}
      onClick={handleClick}
      styles={{ ...buttonStyles }}
    >
      {isLoading && <Loader className={style.loader} type={isSecondary ? 'dark' : 'light'} />}
      <span className={style.text}>{isUploading ? 'Uploading...' : children}</span>
    </Button>
  );
};

export default PrimaryButtonPure;
