import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const MediumIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M2.281 4.438a.716.716 0 0 0-.187-.47L.688 2.282V2H5l3.375 7.406 3-7.406H15.5v.281l-1.219 1.125c-.094.094-.187.188-.094.375v8.438c0 .094 0 .281.094.375l1.125 1.125V14H9.5v-.281l1.219-1.219c.094-.094.094-.188.094-.375V5.281l-3.376 8.532h-.375L3.126 5.28V11c0 .281.094.469.188.656l1.593 1.875v.281H.5v-.28l1.594-1.876c.187-.187.281-.375.187-.656V4.437Z"
        fill={color}
      />
    </svg>
  );
};

export { MediumIcon };
