import React from 'react';

import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';

import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import { usePublicQuestionnaire } from '@bloom/library/components/hooks/usePublicQuestionnaires';
import QuoteRequestModal from '@bloom/library/components/QuoteRequest';
import {
  useQuoteRequest,
  QuoteRequestProvider,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { escapeHTML } from '@bloom/library/utils/string';

const FormButton: React.FC = () => {
  const { publicAccount } = usePublicAccountByCustomUrl();

  const isWebsiteActive = publicAccount?.settings?.isWebsiteActive ?? true;

  const customColor = useCustomColor();

  const [, { showQuoteRequestModal }] = useQuoteRequest();

  const buttonText = publicAccount?.cta?.buttonText || 'Get Started';

  function handleClick() {
    showQuoteRequestModal();
  }

  if (publicAccount?.cta?.buttonFormId) {
    return (
      <>
        <QuoteRequestModal isWebsiteActive={isWebsiteActive} />

        <PrimaryButton
          className="!h-11 text-xs"
          color={customColor}
          data-testid="form-trigger"
          onClick={handleClick}
          style={{ borderRadius: 999, fontFamily: 'Roboto Condensed' }}
          variant="custom"
        >
          <span dangerouslySetInnerHTML={{ __html: escapeHTML(buttonText) }}></span>
        </PrimaryButton>
      </>
    );
  }

  return null;
};

const FormButtonWrapper: React.FC = () => {
  const { publicAccount } = usePublicAccountByCustomUrl();

  const { questionnaire } = usePublicQuestionnaire(
    publicAccount?.id || '',
    publicAccount?.cta?.buttonFormId
  );

  if (questionnaire) {
    return (
      <QuoteRequestProvider defaultValues={{ questionnaire }}>
        <FormButton />
      </QuoteRequestProvider>
    );
  }

  return null;
};

export { FormButtonWrapper };
