__turbopack_context__.v({
  "buttons": "Slideshow-module-scss-module__v-o85W__buttons",
  "darkTheme": "Slideshow-module-scss-module__v-o85W__darkTheme",
  "isDisabled": "Slideshow-module-scss-module__v-o85W__isDisabled",
  "lightbox": "Slideshow-module-scss-module__v-o85W__lightbox",
  "minimalistTemplate": "Slideshow-module-scss-module__v-o85W__minimalistTemplate",
  "minimalistTemplateIcon": "Slideshow-module-scss-module__v-o85W__minimalistTemplateIcon",
  "next": "Slideshow-module-scss-module__v-o85W__next",
  "nordicAirTemplate": "Slideshow-module-scss-module__v-o85W__nordicAirTemplate",
  "nordicAirTemplateIcon": "Slideshow-module-scss-module__v-o85W__nordicAirTemplateIcon",
  "prev": "Slideshow-module-scss-module__v-o85W__prev",
  "slideshowThumbnails": "Slideshow-module-scss-module__v-o85W__slideshowThumbnails",
  "topBar": "Slideshow-module-scss-module__v-o85W__topBar",
});
