import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useFetch } from '@bloom/library/components/hooks/useFetch';

import { SharedQueryKeyEnum } from './interface';

export interface IAnswerGroup {
  id: string;
}

export const useAnswerGroup = (questionnaireId = '') => {
  const client = useQueryClient();

  const { showErrorMessageFromResponse } = useInfoMessage();

  const { post } = useFetch();

  async function fetchAnswerGroup(request: Record<'resourceId' | 'resourceType', string>) {
    return await post<{ ['answer-group']: IAnswerGroup }, unknown>(
      `/api/questionnaires/${questionnaireId}/answer-groups`,
      request
    );
  }

  const { isPending, mutateAsync: fetchAnswerGroupMutation } = useMutation({
    mutationFn: fetchAnswerGroup,
    onSuccess: (res) => {
      if (res.status === AsyncStatusEnum.SUCCESS) {
        client.setQueryData(
          [SharedQueryKeyEnum.ANSWER_GROUP, questionnaireId],
          res.data['answer-group']
        );
      }

      if (res.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(res);
      }
    },
  });

  const { data: answerGroup } = useQuery<IAnswerGroup>({
    queryKey: [SharedQueryKeyEnum.ANSWER_GROUP, questionnaireId],
  });

  return useMemo(
    () => ({ answerGroup, fetchAnswerGroup: fetchAnswerGroupMutation, isLoading: isPending }),
    [answerGroup, fetchAnswerGroupMutation, isPending]
  );
};
