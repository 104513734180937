import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const VimeoIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="m1 5.212.6.772s1.116-.943 1.545-.429c.343.43 1.887 5.92 2.316 6.949.429.858 1.63 2.059 2.917 1.2 1.286-.857 5.576-4.46 6.348-8.835.772-4.29-5.147-3.431-5.748.343 1.458-.943 2.316.343 1.458 1.887-.772 1.459-1.544 2.488-1.887 2.488-.343 0-.686-1.03-1.115-2.745-.429-1.887-.429-5.147-2.316-4.718C3.316 2.467 1 5.212 1 5.212Z"
        fill={color}
      />
    </svg>
  );
};

export { VimeoIcon };
