import React from 'react';

import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';

import PrimaryButtonPure from './PrimaryButtonPure';

const PrimaryButton: React.FC<React.ComponentProps<typeof PrimaryButtonPure>> = (props) => {
  const customColor = useCustomColor(props.customColor);

  return <PrimaryButtonPure {...props} customColor={customColor} />;
};

export default PrimaryButton;
