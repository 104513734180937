import React from 'react';

function ArrowRight({ width = 10, className = '', color = '#000' }) {
  return (
    <svg
      width={width}
      height={width * (11.9 / 10)}
      className={className}
      x="0px"
      y="0px"
      viewBox="0 0 10 11.9"
    >
      <g>
        <path
          fill={color}
          d="M7,5L4.2,1.6C3.9,1.2,3.9,0.5,4.4,0.2c0.4-0.3,1.1-0.3,1.4,0.1l4,5c0.3,0.4,0.3,0.9,0,1.3l-4.1,4.9 c-0.4,0.4-1,0.5-1.4,0.1s-0.5-1-0.1-1.4L6.8,7H1C0.4,7,0,6.6,0,6c0-0.6,0.4-1,1-1H7z"
        />
      </g>
    </svg>
  );
}

export default ArrowRight;
