import { twMerge } from 'tailwind-merge';

import style from './LoaderSpinner.module.scss';

const LoaderSpinner: React.FC<{ className?: string }> = ({ className }) => (
  <div className={twMerge('relative inline-block h-11 w-11', className)}>
    <div className={twMerge(style.loader, 'absolute left-0 top-0 h-11 w-11 rounded-full')} />
  </div>
);

export { LoaderSpinner };
