//
import React from 'react';

function Facebook({ width = 7, className = '', color = '#000' }) {
  return (
    <svg width={width} height={width * (14 / 7)} className={className} viewBox="0 0 7 14">
      <path
        fill={color}
        d="M349,154.2l-2.65-.2H346l-0.57.11-0.28.09a1.45,1.45,0,0,0-.25.12l-0.5.38h0a1.78,1.78,0,0,0-.2.2,1.27,1.27,0,0,0-.18.21,1.44,1.44,0,0,0-.16.25,1.08,1.08,0,0,0-.12.27,0.44,0.44,0,0,0-.06.13,0.53,0.53,0,0,0,0,.15l0,0.07v0.07l0,0.14-0.05,2.51v0H342v2.35h1.56V168h2.91v-6.81l1.86-.07,0.31-2.34-2.15,0,0-1.48a1,1,0,0,1,0-.15,0.92,0.92,0,0,1,0-.15,0.63,0.63,0,0,1,.05-0.13,0.5,0.5,0,0,1,.07-0.12l0.13-.13a0.14,0.14,0,0,1,.08-0.05l0.17-.08,0.05,0,0.15,0h0l1.46,0.15h0L349,154.2h0Z"
        transform="translate(-342 -154)"
      />
    </svg>
  );
}

export default Facebook;
