import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const PinterestIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M8.05 0C3.621 0 0 3.622 0 8.05a7.985 7.985 0 0 0 4.83 7.344c0-.603 0-1.207.1-1.81.201-.705 1.006-4.428 1.006-4.428s-.301-.503-.301-1.308c0-1.207.704-2.113 1.509-2.113.704 0 1.107.503 1.107 1.208 0 .704-.504 1.81-.705 2.817-.201.805.403 1.509 1.308 1.509 1.51 0 2.516-1.912 2.516-4.326 0-1.812-1.208-3.12-3.32-3.12-2.416 0-3.925 1.812-3.925 3.824 0 .704.202 1.207.503 1.61.101.2.202.2.101.402 0 .1-.1.503-.201.604-.1.201-.201.302-.403.201-1.107-.503-1.61-1.71-1.61-3.119 0-2.314 1.912-5.03 5.736-5.03 3.119 0 5.13 2.213 5.13 4.628 0 3.119-1.71 5.533-4.326 5.533-.905 0-1.71-.503-2.012-1.006 0 0-.503 1.811-.604 2.214a8.92 8.92 0 0 1-.804 1.71c.704.201 1.509.302 2.314.302 4.427 0 8.049-3.622 8.049-8.05C16.098 3.623 12.476 0 8.049 0Z"
        fill={color}
      />
    </svg>
  );
};

export { PinterestIcon };
