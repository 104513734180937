import React from 'react';

function Twitter({ width = 17.24, className = '', color = '#000' }) {
  return (
    <svg width={width} height={width * (13 / 16)} className={className} viewBox="0 0 16 13">
      <path
        fill={color}
        d="M465,155.54a6.59,6.59,0,0,1-1.89.52,3.29,3.29,0,0,0,1.44-1.82,6.57,6.57,0,0,1-2.08.8,3.29,3.29,0,0,0-5.59,3,9.32,9.32,0,0,1-6.77-3.43,3.28,3.28,0,0,0,1,4.38,3.26,3.26,0,0,1-1.49-.41v0a3.29,3.29,0,0,0,2.63,3.22,3.29,3.29,0,0,1-1.48.06,3.29,3.29,0,0,0,3.07,2.28,6.59,6.59,0,0,1-4.08,1.4,6.75,6.75,0,0,1-.78,0,9.34,9.34,0,0,0,14.37-7.86c0-.14,0-0.28,0-0.42a6.67,6.67,0,0,0,1.64-1.7"
        transform="translate(-449 -154)"
      />
    </svg>
  );
}

export default Twitter;
