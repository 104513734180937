import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const TikTokIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} height={width} viewBox="0 0 24 24" width={width}>
      <path
        d="M6 3C4.355 3 3 4.355 3 6v12c0 1.645 1.355 3 3 3h12c1.645 0 3-1.355 3-3V6c0-1.645-1.355-3-3-3H6zm0 2h12c.565 0 1 .435 1 1v12c0 .565-.435 1-1 1H6c-.565 0-1-.435-1-1V6c0-.565.435-1 1-1zm6 2v7c0 .565-.435 1-1 1s-1-.435-1-1 .435-1 1-1v-2c-1.645 0-3 1.355-3 3s1.355 3 3 3 3-1.355 3-3v-3.768c.616.44 1.26.768 2 .768V9c-.047 0-.737-.22-1.219-.639C14.3 7.941 14 7.415 14 7h-2z"
        fill={color}
      />
    </svg>
  );
};

export { TikTokIcon };
