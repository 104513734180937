import React from 'react';

import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';

export default (WrappedComponent) => {
  function ActiveTemplate(props) {
    const activeTemplate = useActiveTemplate();

    return <WrappedComponent {...props} activeTemplate={activeTemplate} />;
  }

  return ActiveTemplate;
};
