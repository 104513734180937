import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const FacebookIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M16 8c0-4.4-3.6-8-8-8S0 3.6 0 8c0 4 2.9 7.3 6.7 7.9v-5.6h-2V8h2V6.2c0-2 1.2-3.1 3-3.1.9 0 1.8.2 1.8.2v2h-1c-1 0-1.3.6-1.3 1.2V8h2.2l-.4 2.3H9.1V16c4-.6 6.9-4 6.9-8Z"
        fill={color}
      />
    </svg>
  );
};

export { FacebookIcon };
