import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const AdobePortfolioIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="m9.662 7.183.981.029.011 5.172c0 .083.037.125.112.125h1.586c.056 0 .09-.014.104-.042a.189.189 0 0 0 .021-.083l-.01-5.172h1.36c.084 0 .126-.032.126-.098V5.888a.139.139 0 0 0-.021-.083c-.014-.019-.049-.028-.104-.028h-1.362V5.22a1.73 1.73 0 0 1 .09-.62.596.596 0 0 1 .28-.328c.176-.067.363-.1.552-.097h.209c.074 0 .226.004.291.014.075.018.112-.005.112-.07v-1.38a.143.143 0 0 0-.098-.139 2.015 2.015 0 0 0-.402-.076 3.565 3.565 0 0 0-.405-.021 2.329 2.329 0 0 0-1.365.362c-.34.238-.614.56-.794.933-.106.224-.18.46-.223.704-.048.414-.072.83-.07 1.247v.028l-.981-.011a.086.086 0 0 0-.098.098v1.221c0 .056.033.089.098.098Z"
        fill={color}
      />
      <path
        clipRule="evenodd"
        d="M2 3.443v8.903c0 .074.033.111.098.111h1.63a.11.11 0 0 0 .126-.125V9.587c.167 0 .292.003.376.007.083.005.227.007.431.007a5.544 5.544 0 0 0 1.951-.327 2.91 2.91 0 0 0 1.393-1.052 3.095 3.095 0 0 0 .516-1.84 3.262 3.262 0 0 0-.195-1.12 2.726 2.726 0 0 0-.634-.983 3.095 3.095 0 0 0-1.164-.71 5.235 5.235 0 0 0-1.783-.265c-.26 0-.522.002-.787.007a485.382 485.382 0 0 0-1.874.035c-.056 0-.084.032-.084.097Zm1.854 4.458V5.017c.064-.009.183-.016.355-.02.172-.005.369-.007.592-.007.347-.011.693.048 1.017.174a1.3 1.3 0 0 1 .606.494c.14.231.21.497.202.767.02.32-.077.636-.272.891a1.503 1.503 0 0 1-.71.46 3.286 3.286 0 0 1-.997.14h-.46a4.3 4.3 0 0 1-.333-.015Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export { AdobePortfolioIcon };
