import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const TelegramIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M1.995 7.166c3.887-1.693 6.48-2.81 7.776-3.35C13.474 2.277 14.243 2.01 14.745 2c.11-.002.357.026.516.155.135.11.172.258.19.361.018.104.04.34.022.525-.2 2.108-1.068 7.225-1.51 9.586-.187 1-.555 1.334-.911 1.367-.775.071-1.363-.512-2.112-1.003-1.174-.77-1.837-1.248-2.976-1.999-1.316-.868-.463-1.344.287-2.123.196-.204 3.608-3.307 3.674-3.589.008-.035.016-.166-.062-.236-.078-.069-.193-.045-.276-.026-.118.026-1.992 1.265-5.624 3.717-.532.365-1.014.543-1.446.534-.476-.01-1.392-.27-2.073-.49-.835-.272-1.498-.415-1.44-.876.03-.24.36-.486.991-.737Z"
        fill={color}
      />
    </svg>
  );
};

export { TelegramIcon };
