import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const YoutubeIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M15.6 3.7c-.2-.7-.7-1.2-1.4-1.4C13 2 7.9 2 7.9 2s-5 0-6.3.3C.9 2.5.4 3 .2 3.7 0 5 0 7.6 0 7.6s0 2.6.3 3.9c.2.7.7 1.2 1.4 1.4 1.2.3 6.3.3 6.3.3s5 0 6.3-.3c.7-.2 1.2-.7 1.4-1.4.3-1.3.3-3.9.3-3.9s0-2.6-.4-3.9ZM6.4 10V5.2l4.2 2.4L6.4 10Z"
        fill={color}
      />
    </svg>
  );
};

export { YoutubeIcon };
