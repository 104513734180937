import React from 'react';

import FacebookIcon from '@bloom/library/components/Icon/Facebook';
import InstagramIcon from '@bloom/library/components/Icon/Instagram';
import LinkedInIcon from '@bloom/library/components/Icon/LinkedIn';
import PinterestIcon from '@bloom/library/components/Icon/Pinterest';
import TumblrIcon from '@bloom/library/components/Icon/Tumblr';
import TwitterIcon from '@bloom/library/components/Icon/Twitter';
import VimeoIcon from '@bloom/library/components/Icon/Vimeo';
import YoutubeIcon from '@bloom/library/components/Icon/Youtube';
import {
  SOCIAL_LINK_TYPE_FACEBOOK,
  SOCIAL_LINK_TYPE_INSTAGRAM,
  SOCIAL_LINK_TYPE_LINKEDIN,
  SOCIAL_LINK_TYPE_PINTEREST,
  SOCIAL_LINK_TYPE_TUMBLR,
  SOCIAL_LINK_TYPE_TWITTER,
  SOCIAL_LINK_TYPE_VIMEO,
  SOCIAL_LINK_TYPE_YOUTUBE,
} from '@bloom/library/components/Portal/Form/SocialMediaLinks/constants';
import { ensureValidUrl } from '@bloom/library/utils/misc';

function SocialLink({ link, isLarge, style }) {
  if (!link) {
    return null;
  }

  let icon;
  let width;
  switch (link.name) {
    case SOCIAL_LINK_TYPE_FACEBOOK:
      width = isLarge ? 8 : 6.22;
      icon = <FacebookIcon width={width} />;
      break;
    case SOCIAL_LINK_TYPE_TWITTER:
      width = isLarge ? 17 : 12.24;
      icon = <TwitterIcon width={width} />;
      break;
    case SOCIAL_LINK_TYPE_INSTAGRAM:
      width = isLarge ? 16 : 12.24;
      icon = <InstagramIcon width={width} />;
      break;
    case SOCIAL_LINK_TYPE_PINTEREST:
      width = isLarge ? 12.5 : 9.8;
      icon = <PinterestIcon width={width} />;
      break;
    case SOCIAL_LINK_TYPE_YOUTUBE:
      width = isLarge ? 12 : 12;
      icon = <YoutubeIcon width={width} />;
      break;
    case SOCIAL_LINK_TYPE_LINKEDIN:
      width = isLarge ? 14 : 14;
      icon = <LinkedInIcon width={width} />;
      break;
    case SOCIAL_LINK_TYPE_VIMEO:
      width = isLarge ? 14 : 14;
      icon = <VimeoIcon width={width} />;
      break;
    case SOCIAL_LINK_TYPE_TUMBLR:
      width = isLarge ? 9 : 9;
      icon = <TumblrIcon width={width} />;
      break;
    default:
  }

  return icon ? (
    <a
      // Make sure that urls without 'https://' work,
      // e.g. www.twitter.com/shutterbug
      className={link.name}
      href={ensureValidUrl(link.url)}
      rel="noopener noreferrer"
      style={style}
      target="_blank"
    >
      {icon}
    </a>
  ) : null;
}

export default SocialLink;
