import React from 'react';

function Tumblr({ width = 9, className = '', color = '#000' }) {
  return (
    <svg width={width} height={width * (14 / 9)} className={className} viewBox="0 0 9 14">
      <g
        fill={color}
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        transform="translate(-1062.000000, -1974.000000) translate(571.000000, 1813.000000) translate(72.000000, 156.000000) translate(272.000000, 5.000000)"
      >
        <path d="M151.838615,0 L151.838615,3.53868828 L155.160649,3.53868828 L155.160649,5.73618276 L151.838615,5.73618276 L151.838615,9.32645545 C151.838615,10.1380537 151.881601,10.6590507 151.967576,10.8894621 C152.05355,11.1198734 152.21346,11.3038559 152.44731,11.4414149 C152.756818,11.6271196 153.111027,11.7199706 153.509949,11.7199706 C154.218377,11.7199706 154.923357,11.4895626 155.624908,11.0287399 L155.624908,13.2365512 C155.026526,13.5185472 154.484896,13.7162854 154,13.8297716 C153.515105,13.9432578 152.990669,14 152.426677,14 C151.787027,14 151.221324,13.9191853 150.729551,13.7575534 C150.237777,13.5959216 149.818229,13.3655137 149.470892,13.0663228 C149.123555,12.7671319 148.882831,12.4490314 148.74871,12.1120118 C148.614591,11.7749922 148.547531,11.286665 148.547531,10.6470155 L148.547531,5.73618276 L147,5.73618276 L147,3.75534267 C147.550236,3.57651592 148.021369,3.3203161 148.413412,2.98673547 C148.805455,2.65315483 149.120117,2.25252015 149.357406,1.78481944 C149.594696,1.31711877 149.758045,0.722184873 149.847458,0 L151.838615,0 Z" />
      </g>
    </svg>
  );
}

export default Tumblr;
