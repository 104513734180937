import React from 'react';

import { AdobePortfolioIcon } from '@bloom/ui/components/Icons/AdobePortfolio';
import { BehanceIcon } from '@bloom/ui/components/Icons/Behance';
import { DribbbleIcon } from '@bloom/ui/components/Icons/Dribbble';
import { FacebookIcon } from '@bloom/ui/components/Icons/Facebook';
import { FigmaIcon } from '@bloom/ui/components/Icons/Figma';
import { FlickrIcon } from '@bloom/ui/components/Icons/Flickr';
import { InstagramIcon } from '@bloom/ui/components/Icons/Instagram';
import { LinkedinIcon } from '@bloom/ui/components/Icons/Linkedin';
import { MediumIcon } from '@bloom/ui/components/Icons/Medium';
import { PinterestIcon } from '@bloom/ui/components/Icons/Pinterest';
import { TelegramIcon } from '@bloom/ui/components/Icons/Telegram';
import { TikTokIcon } from '@bloom/ui/components/Icons/TikTok';
import { TumblrIcon } from '@bloom/ui/components/Icons/Tumblr';
import { TwitterIcon } from '@bloom/ui/components/Icons/Twitter';
import { VimeoIcon } from '@bloom/ui/components/Icons/Vimeo';
import { YoutubeIcon } from '@bloom/ui/components/Icons/Youtube';

export const SOCIAL_MEDIA_LINK = 'draggable social media link';

export const SOCIAL_LINK_TYPE_ADOBE_PORTFOLIO = 'adobe-portfolio';
export const SOCIAL_LINK_TYPE_BEHANCE = 'behance';
export const SOCIAL_LINK_TYPE_DRIBBBLE = 'dribbble';
export const SOCIAL_LINK_TYPE_FACEBOOK = 'facebook';
export const SOCIAL_LINK_TYPE_FIGMA = 'figma';
export const SOCIAL_LINK_TYPE_FLICKR = 'flickr';
export const SOCIAL_LINK_TYPE_INSTAGRAM = 'instagram';
export const SOCIAL_LINK_TYPE_LINKEDIN = 'linkedin';
export const SOCIAL_LINK_TYPE_MEDIUM = 'medium';
export const SOCIAL_LINK_TYPE_PINTEREST = 'pinterest';
export const SOCIAL_LINK_TYPE_SNAPCHAT = 'snapchat';
export const SOCIAL_LINK_TYPE_TELEGRAM = 'telegram';
export const SOCIAL_LINK_TYPE_TUMBLR = 'tumblr';
export const SOCIAL_LINK_TYPE_TWITTER = 'twitter';
export const SOCIAL_LINK_TYPE_VIMEO = 'vimeo';
export const SOCIAL_LINK_TYPE_YOUTUBE = 'youtube';

export const SOCIAL_LINK_TYPE_TIK_TOK = 'tik-tok';

export const socialMediaLinks = {
  [SOCIAL_LINK_TYPE_ADOBE_PORTFOLIO]: {
    icon: <AdobePortfolioIcon width={16} />,
    label: 'Adobe Portfolio',
  },
  [SOCIAL_LINK_TYPE_BEHANCE]: { icon: <BehanceIcon width={16} />, label: 'Behance' },
  [SOCIAL_LINK_TYPE_DRIBBBLE]: { icon: <DribbbleIcon width={16} />, label: 'Dribbble' },
  [SOCIAL_LINK_TYPE_FACEBOOK]: { icon: <FacebookIcon width={16} />, label: 'Facebook' },
  [SOCIAL_LINK_TYPE_FIGMA]: { icon: <FigmaIcon width={16} />, label: 'Figma' },
  [SOCIAL_LINK_TYPE_FLICKR]: { icon: <FlickrIcon width={16} />, label: 'Flickr' },
  [SOCIAL_LINK_TYPE_INSTAGRAM]: { icon: <InstagramIcon width={16} />, label: 'Instagram' },
  [SOCIAL_LINK_TYPE_LINKEDIN]: { icon: <LinkedinIcon width={16} />, label: 'LinkedIn' },
  [SOCIAL_LINK_TYPE_MEDIUM]: { icon: <MediumIcon width={16} />, label: 'Medium' },
  [SOCIAL_LINK_TYPE_PINTEREST]: { icon: <PinterestIcon width={16} />, label: 'Pinterest' },
  [SOCIAL_LINK_TYPE_TELEGRAM]: { icon: <TelegramIcon width={16} />, label: 'Telegram' },
  [SOCIAL_LINK_TYPE_TIK_TOK]: { icon: <TikTokIcon width={16} />, label: 'Tik Tok' },
  [SOCIAL_LINK_TYPE_TUMBLR]: { icon: <TumblrIcon width={16} />, label: 'Tumblr' },
  [SOCIAL_LINK_TYPE_TWITTER]: { icon: <TwitterIcon width={16} />, label: 'Twitter' },
  [SOCIAL_LINK_TYPE_VIMEO]: { icon: <VimeoIcon width={16} />, label: 'Vimeo' },
  [SOCIAL_LINK_TYPE_YOUTUBE]: { icon: <YoutubeIcon width={16} />, label: 'YouTube' },
};
