import React from 'react';

function LinkedIn({ width = 14, className = '', color = '#000' }) {
  return (
    <svg width={width} height={width} className={className} viewBox="0 0 14 14">
      <g
        transform="translate(-982.000000, -1940.000000) translate(571.000000, 1813.000000)"
        fill={color}
      >
        <path d="M411.226677,131.648649 L414.130301,131.648649 L414.130301,141 L411.226677,141 L411.226677,131.648649 Z M412.683886,127 C413.612182,127 414.367772,127.756757 414.367772,128.686486 C414.367772,129.616216 413.612182,130.372973 412.683886,130.372973 C411.750193,130.372973 411,129.616216 411,128.686486 C411,127.756757 411.750193,127 412.683886,127 Z M415.95451,131.648649 L418.734002,131.648649 L418.734002,132.92973 L418.771781,132.92973 C419.16037,132.194595 420.104857,131.421622 421.51889,131.421622 C424.454896,131.421622 425,133.356757 425,135.875676 L425,141 L422.101773,141 L422.101773,136.454054 C422.101773,135.367568 422.085582,133.972973 420.595991,133.972973 C419.084811,133.972973 418.858134,135.156757 418.858134,136.372973 L418.858134,141 L415.959907,141 L415.959907,131.648649 L415.95451,131.648649 Z" />
      </g>
    </svg>
  );
}

export default LinkedIn;
