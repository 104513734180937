import React from 'react';

import { IIconProps } from './types';

export const InstagramIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => (
  <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
    <path
      d="M8.5 2.355h3.072c.723 0 1.085.181 1.356.272.361.18.632.27.903.542.271.27.452.542.543.903.09.271.18.633.27 1.356v6.144c0 .723-.18 1.085-.27 1.356-.181.361-.272.632-.543.903-.27.271-.542.452-.903.543-.271.09-.633.18-1.356.27H5.428c-.723 0-1.085-.18-1.356-.27-.361-.181-.632-.272-.903-.543-.271-.27-.452-.542-.542-.903-.09-.271-.181-.633-.272-1.356V5.428c0-.723.181-1.085.272-1.356.18-.361.27-.632.542-.903.27-.271.542-.452.903-.542.271-.09.633-.181 1.356-.272H8.5ZM8.5 1H5.428c-.814 0-1.356.18-1.808.361-.451.181-.903.452-1.355.904-.452.452-.632.813-.904 1.356-.18.451-.27.993-.361 1.807v6.144c0 .814.18 1.356.361 1.808.181.451.452.903.904 1.355.452.452.813.633 1.355.904.452.18.994.27 1.808.361h6.144c.814 0 1.356-.18 1.808-.361.451-.181.903-.452 1.355-.904.452-.452.633-.813.904-1.355.18-.452.27-.994.361-1.808V5.428c0-.814-.18-1.356-.361-1.807a3.947 3.947 0 0 0-.904-1.356c-.452-.452-.813-.632-1.355-.904-.452-.18-.995-.27-1.808-.361H8.5Z"
      fill={color}
    />
    <path
      d="M8.5 4.614A3.857 3.857 0 0 0 4.614 8.5 3.857 3.857 0 0 0 8.5 12.386 3.857 3.857 0 0 0 12.386 8.5 3.857 3.857 0 0 0 8.5 4.614Zm0 6.416A2.526 2.526 0 0 1 5.97 8.5 2.526 2.526 0 0 1 8.5 5.97a2.526 2.526 0 0 1 2.53 2.53c0 1.355-1.175 2.53-2.53 2.53ZM12.476 5.428a.904.904 0 1 0 0-1.807.904.904 0 0 0 0 1.807Z"
      fill={color}
    />
  </svg>
);
