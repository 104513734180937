import React from 'react';

function ArrowLeft({ width = 10, className = '', color = '#525252', rotate = 0 }) {
  const height = width * (11.9 / 10);
  return (
    <svg className={className} height={height} width={width} viewBox="0 0 10 11.9">
      <path
        fill={color}
        d="M3.2,4.8H9c0.6,0,1,0.4,1,1s-0.4,1-1,1H3l2.8,3.4l0,0c0.3,0.4,0.2,1.1-0.2,1.4l0,0c-0.4,0.4-1,0.3-1.4-0.1 l-4-5c-0.3-0.4-0.3-0.9,0-1.3l4.2-4.8c0.3-0.5,0.9-0.5,1.4-0.2c0.4,0.3,0.5,1,0.1,1.4L3.2,4.8z"
        transform={`rotate(${rotate} ${width / 2} ${height / 2})`}
      />
    </svg>
  );
}

export default ArrowLeft;
