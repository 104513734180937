import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const BehanceIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M5.072 4c.404 0 .777.031 1.119.124.342.063.622.187.87.342.25.156.436.373.56.653.124.28.186.622.186.995 0 .435-.093.808-.31 1.088-.187.28-.498.528-.87.715.528.155.932.435 1.18.808.25.373.404.839.404 1.367 0 .436-.093.809-.248 1.12-.156.31-.404.59-.684.777a3.22 3.22 0 0 1-.995.435 4.626 4.626 0 0 1-1.119.155H1V4h4.072Zm-.249 3.481c.342 0 .622-.093.84-.248.217-.156.31-.435.31-.777 0-.187-.03-.373-.093-.498a.882.882 0 0 0-.28-.31 1.562 1.562 0 0 0-.404-.156c-.155-.031-.31-.031-.497-.031H2.896v2.02h1.927Zm.094 3.668c.186 0 .373-.03.528-.062a1.09 1.09 0 0 0 .746-.528c.062-.156.124-.342.124-.56 0-.435-.124-.746-.373-.963-.248-.187-.59-.28-.994-.28H2.896v2.393h2.02Zm5.999-.03c.248.248.621.372 1.119.372a1.7 1.7 0 0 0 .901-.248c.249-.187.404-.373.466-.56h1.524c-.249.746-.622 1.274-1.12 1.616-.497.311-1.087.498-1.802.498-.498 0-.933-.094-1.337-.249a2.484 2.484 0 0 1-.995-.684 2.467 2.467 0 0 1-.621-1.026c-.156-.404-.218-.839-.218-1.336a3.6 3.6 0 0 1 .218-1.306c.155-.404.373-.746.653-1.057.28-.28.621-.528.994-.683a3.488 3.488 0 0 1 1.306-.249c.528 0 .994.093 1.399.31.404.218.714.467.963.84.249.342.435.746.56 1.181.062.435.093.87.062 1.368H10.48c0 .497.186.964.435 1.212Zm1.958-3.265c-.218-.217-.56-.342-.963-.342-.28 0-.498.063-.684.156-.187.093-.311.218-.436.342a.828.828 0 0 0-.217.435c-.031.155-.062.28-.062.404h2.797c-.062-.466-.217-.777-.435-.995ZM10.14 4.591h3.481v.839H10.14v-.84Z"
        fill={color}
      />
    </svg>
  );
};

export { BehanceIcon };
