import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const LinkedinIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M4.7 14H2.15V5.975H4.7V14ZM3.425 4.85C2.6 4.85 2 4.25 2 3.425 2 2.6 2.675 2 3.425 2c.825 0 1.425.6 1.425 1.425 0 .825-.6 1.425-1.425 1.425ZM14 14h-2.55V9.65c0-1.275-.525-1.65-1.275-1.65-.75 0-1.5.6-1.5 1.725V14h-2.55V5.975h2.4V7.1c.225-.525 1.125-1.35 2.4-1.35 1.425 0 2.925.825 2.925 3.3V14H14Z"
        fill={color}
      />
    </svg>
  );
};

export { LinkedinIcon };
