import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const FlickrIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M16 7.73a3.728 3.728 0 0 1-3.73 3.73 3.728 3.728 0 0 1-3.73-3.73A3.728 3.728 0 0 1 12.27 4 3.728 3.728 0 0 1 16 7.73ZM7.46 7.73a3.728 3.728 0 0 1-3.73 3.73A3.728 3.728 0 0 1 0 7.73 3.728 3.728 0 0 1 3.73 4c1.963 0 3.73 1.669 3.73 3.73Z"
        fill={color}
      />
    </svg>
  );
};

export { FlickrIcon };
