'use client';
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ProgressiveImage from '@bloom/library/components/ProgressiveImage';
import { isHoverCapable } from '@bloom/library/utils/browser';
import { repeatedly } from '@bloom/library/utils/function';

import style from './Thumbnails.module.scss';
import { twMerge } from 'tailwind-merge';

function getScrollableWidth(el) {
  if (el) {
    return el.scrollWidth - el.offsetWidth;
  }

  return 0;
}

function clamp(min, max, value) {
  return Math.max(min, Math.min(value, max));
}

export default class Thumbnails extends Component {
  static propTypes = {
    selectedIndex: PropTypes.number.isRequired,
    onSelect: PropTypes.func,
    items: PropTypes.array,
    className: PropTypes.string,
  };

  static defaultProps = {
    items: [],
    onSelect: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isHoverCapable: false,
      scrollDirection: 0,
      scrollLeft: 0,
      imageHeight: undefined,
      loadedImages: [],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setImageHeight);
    isHoverCapable().then(() => this.setState({ isHoverCapable: true }));
    this.setImageHeight();
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollLeft } = this.state;

    if (prevState.scrollLeft !== scrollLeft) {
      this.container.scrollLeft = scrollLeft;
    }

    if (prevProps.selectedIndex !== this.props.selectedIndex) {
      this.ensureThumbnailIsVisible();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setImageHeight);
  }

  setImageHeight = () => {
    const { height } = this.container.parentNode.getBoundingClientRect();
    const { imageHeight } = this.state;
    if (imageHeight !== height) {
      this.setState({ imageHeight: height });
    }
  };

  setScrollLeft(next) {
    const max = getScrollableWidth(this.container);
    this.setState({
      scrollLeft: clamp(0, max, next),
    });
  }

  setScrollDirection(ev) {
    const { left: offsetLeft, width } = this.container.getBoundingClientRect();
    const steps = 12;

    this.setState({
      scrollDirection: Math.round((steps * (ev.pageX - offsetLeft)) / width) - steps / 2,
    });
  }

  startScrolling() {
    this.stopScrolling();
    this.cancelScrolling = repeatedly(this.moveScroll.bind(this));
  }

  moveScroll() {
    const { scrollDirection } = this.state;
    this.setScrollLeft(this.state.scrollLeft + scrollDirection);
  }

  stopScrolling() {
    if (this.cancelScrolling) {
      this.cancelScrolling();
    }
  }

  ensureThumbnailIsVisible() {
    const thumb = this.thumbnailRefs[this.props.selectedIndex];
    // Don't center thumbnails if they aren't displayed
    if (!thumb) {
      return;
    }
    const min = thumb.offsetLeft + thumb.offsetWidth - this.container.offsetWidth;
    const max = thumb.offsetLeft;
    const safe = clamp(min, max, this.state.scrollLeft);

    this.setScrollLeft(safe);
  }

  handleImageLoad = (image) => {
    const { loadedImages } = this.state;
    this.setState({ loadedImages: [...loadedImages, image.id] });
  };

  render() {
    const { items, selectedIndex, onSelect, className, isCdnOn } = this.props;
    const hoverListeners = this.state.isHoverCapable
      ? {
          onMouseEnter: this.startScrolling.bind(this),
          onMouseMove: this.setScrollDirection.bind(this),
          onMouseLeave: this.stopScrolling.bind(this),
        }
      : {};
    const { imageHeight, loadedImages } = this.state;

    this.thumbnailRefs = [];

    return (
      <div
        className={twMerge(
          style.container,
          this.state.isHoverCapable ? style.isHoverCapable : '',
          className
        )}
        {...hoverListeners}
      >
        <div className={style.thumbnails} ref={(node) => (this.container = node)}>
          {imageHeight > 0
            ? items.map((item, index) => {
                const imageWidth = (item.width * imageHeight) / item.height;
                return (
                  <div
                    className={twMerge(
                      style.thumbnail,
                      selectedIndex === index ? style.isActive : '',
                      loadedImages.indexOf(item.id) > -1 ? style.loaded : ''
                    )}
                    key={index}
                    onClick={onSelect.bind(null, index)}
                    ref={(node) => (this.thumbnailRefs[index] = node)}
                    style={{ width: imageWidth, height: imageHeight }}
                  >
                    <ProgressiveImage
                      className={style.image}
                      image={item}
                      onLoad={this.handleImageLoad.bind(null, item)}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}
