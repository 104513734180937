import React from 'react';

function Vimeo({ width = 14, className = '', color = '#000' }) {
  return (
    <svg width={width} height={width * (12 / 14)} className={className} viewBox="0 0 14 12">
      <g
        transform="translate(-944.000000, -1942.000000) translate(571.000000, 1813.000000)"
        fill={color}
      >
        <path d="M386.707438,131.771787 C386.647741,133.123957 385.715543,134.973317 383.910845,137.319867 C382.04645,139.773289 380.466765,141 379.176383,141 C378.377357,141 377.697725,140.251892 377.146672,138.760323 C376.779304,137.389566 376.407343,136.023456 376.039975,134.6527 C375.631277,133.16113 375.190435,132.413022 374.717448,132.413022 C374.616422,132.413022 374.253645,132.631414 373.642895,133.068198 L373,132.227157 C373.67504,131.627742 374.345487,131.023679 375.002158,130.419617 C375.906803,129.629689 376.581843,129.216139 377.036462,129.174319 C378.10183,129.072093 378.758502,129.810908 379.006475,131.390763 C379.272818,133.096078 379.456502,134.15551 379.56212,134.56906 C379.869792,135.981637 380.209607,136.692571 380.576976,136.692571 C380.861687,136.692571 381.293345,136.232555 381.87195,135.317168 C382.445963,134.401781 382.753634,133.70014 382.794963,133.221537 C382.877621,132.431609 382.56995,132.031999 381.87195,132.031999 C381.541318,132.031999 381.206095,132.106345 380.857094,132.259684 C381.532134,130.024653 382.817924,128.941988 384.719056,129.002394 C386.124241,129.03492 386.790096,129.9596 386.707438,131.771787 Z" />
      </g>
    </svg>
  );
}

export default Vimeo;
