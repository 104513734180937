import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const FigmaIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M7.667 8a2.333 2.333 0 1 1 4.667 0 2.333 2.333 0 0 1-4.667 0ZM3 12.667a2.333 2.333 0 0 1 2.333-2.334h2.334v2.334a2.333 2.333 0 0 1-4.667 0Z"
        stroke={color}
      />
      <path
        d="M7.667 1v4.667H10A2.333 2.333 0 1 0 10 1H7.667ZM3 3.333a2.333 2.333 0 0 0 2.333 2.334h2.334V1H5.333A2.333 2.333 0 0 0 3 3.333ZM3 8a2.333 2.333 0 0 0 2.333 2.333h2.334V5.667H5.333A2.333 2.333 0 0 0 3 8Z"
        stroke={color}
      />
    </svg>
  );
};

export { FigmaIcon };
