import React from 'react';

function Pinterest({ width = 11, className = '', color = '#000' }) {
  return (
    <svg width={width} height={(width * 14) / 11} className={className} viewBox="0 0 11 14">
      <path
        fill={color}
        d="M379.83,154c-3.88,0-5.83,2.74-5.83,5a3.09,3.09,0,0,0,1.67,3.07,0.28,0.28,0,0,0,.41-0.2l0.17-.65a0.39,0.39,0,0,0-.12-0.45,2.29,2.29,0,0,1-.54-1.58,3.84,3.84,0,0,1,4-3.85c2.19,0,3.4,1.32,3.4,3.08,0,2.32-1,4.27-2.59,4.27a1.25,1.25,0,0,1-1.29-1.55,17,17,0,0,0,.72-2.85,1.08,1.08,0,0,0-1.1-1.21,1.85,1.85,0,0,0-1.57,2.08,3.06,3.06,0,0,0,.26,1.27l-1.05,4.38a8.89,8.89,0,0,0,0,3.06,0.11,0.11,0,0,0,.19,0,8.55,8.55,0,0,0,1.47-2.63c0.1-.36.57-2.21,0.57-2.21a2.35,2.35,0,0,0,2,1c2.62,0,4.4-2.35,4.4-5.51a4.85,4.85,0,0,0-5.17-4.61"
        transform="translate(-374 -154)"
      />
    </svg>
  );
}

export default Pinterest;
