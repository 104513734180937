import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { AlbumResponse } from '@bloom/codegen/models/AlbumResponse';

import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useFetch } from '@bloom/library/components/hooks/useFetch';

import { QueryKeyEnum } from '@bloom/portal/components/hooks/interface';

export interface IPublicPortfolio {
  albumArrangement: Record<string, number>;
  albums: Array<AlbumResponse>;
  slideshowGallery: { albums: Array<AlbumResponse> };
}

export const usePortfolio = (userId?: string) => {
  const { get, status } = useFetch();

  const defaultData: IPublicPortfolio = useMemo(
    () => ({ albumArrangement: {}, albums: [], slideshowGallery: { albums: [] } }),
    []
  );

  async function fetchPortfolio() {
    const response = await get<{
      portfolio: IPublicPortfolio;
    }>(`/api/public-portfolios/${userId}`);

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data.portfolio;
    }

    return defaultData;
  }
  const queryResult = useQuery({
    enabled: !!userId,
    queryFn: fetchPortfolio,
    queryKey: [QueryKeyEnum.PUBLIC_PORTFOLIO, userId],
  });

  return useMemo(
    () => ({ ...queryResult, data: queryResult.data || defaultData, status }),
    [defaultData, queryResult, status]
  );
};
