__turbopack_context__.v({
  "button": "PrimaryButton-module-scss-module__4y0wga__button",
  "disabled": "PrimaryButton-module-scss-module__4y0wga__disabled",
  "loader": "PrimaryButton-module-scss-module__4y0wga__loader",
  "loading": "PrimaryButton-module-scss-module__4y0wga__loading",
  "primary": "PrimaryButton-module-scss-module__4y0wga__primary",
  "secondary": "PrimaryButton-module-scss-module__4y0wga__secondary",
  "text": "PrimaryButton-module-scss-module__4y0wga__text",
  "thinBorder": "PrimaryButton-module-scss-module__4y0wga__thinBorder",
  "uploading": "PrimaryButton-module-scss-module__4y0wga__uploading",
});
