import React from 'react';

function ArrowLeftLight({ width = 10, className = '', color = '#000' }) {
  return (
    <svg
      width={width}
      height={width * (20 / 42)}
      className={className}
      x="0px"
      y="0px"
      viewBox="0 -1 42 20"
    >
      <g>
        <polyline fill="transparent" stroke={color} points="8 0 0 7.5 8 15" />
        <rect fill={color} x="0" y="7" width="40" height="1" />
      </g>
    </svg>
  );
}

export default ArrowLeftLight;
