import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const TwitterIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M16 3.40625C15.4375 3.6875 14.875 3.78125 14.2188 3.875C14.875 3.5 15.3438 2.9375 15.5312 2.1875C14.9688 2.5625 14.3125 2.75 13.5625 2.9375C13 2.375 12.1562 2 11.3125 2C9.34375 2 7.84375 3.875 8.3125 5.75C5.78125 5.65625 3.53125 4.4375 1.9375 2.5625C1.09375 3.96875 1.5625 5.75 2.875 6.6875C2.40625 6.6875 1.9375 6.5 1.46875 6.3125C1.46875 7.71875 2.5 9.03125 3.90625 9.40625C3.4375 9.5 2.96875 9.59375 2.5 9.5C2.875 10.7187 4 11.6562 5.40625 11.6562C4.28125 12.5 2.59375 12.9687 1 12.7812C2.40625 13.625 4 14.1875 5.6875 14.1875C11.4062 14.1875 14.5937 9.40625 14.4062 5C15.0625 4.625 15.625 4.0625 16 3.40625Z"
        fill={color}
      />
    </svg>
  );
};

export { TwitterIcon };
