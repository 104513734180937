import React from 'react';

import { IIconProps } from './types';

export const LikeIcon: React.FC<IIconProps> = ({ width = 20, className = '', color = '#000' }) => (
  <svg className={className} fill="none" height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M12.051 2.078a5.417 5.417 0 0 1 5.905 8.836l-7.367 7.367a.833.833 0 0 1-1.178 0l-7.367-7.367a5.418 5.418 0 0 1 7.662-7.662l.294.295.294-.294a5.418 5.418 0 0 1 1.757-1.175Zm2.074 1.254a3.75 3.75 0 0 0-2.652 1.099l-.884.883a.833.833 0 0 1-1.178 0l-.884-.883a3.751 3.751 0 1 0-5.305 5.305L10 16.513l6.777-6.777a3.752 3.752 0 0 0-2.652-6.404Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
