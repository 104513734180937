import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const DribbbleIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 16,
}) => {
  return (
    <svg className={className} fill="none" height={width} viewBox="0 0 16 16" width={width}>
      <path
        d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.4 0 8-3.584 8-8s-3.6-8-8-8Zm5.28 3.68a6.862 6.862 0 0 1 1.552 4.256c-.224-.048-2.48-.496-4.752-.224-.048-.112-.096-.24-.144-.352a18.66 18.66 0 0 0-.448-.992c2.496-1.008 3.648-2.496 3.792-2.688ZM8 1.184c1.728 0 3.328.656 4.528 1.712-.128.176-1.152 1.552-3.584 2.464-1.12-2.064-2.368-3.744-2.544-4A7.428 7.428 0 0 1 8 1.184Zm-2.912.64c.176.24 1.392 1.936 2.528 3.952-3.2.848-6.016.832-6.32.832a6.914 6.914 0 0 1 3.792-4.784Zm-3.92 6.192v-.208c.288.016 3.616.048 7.024-.976.192.384.384.768.56 1.168-.08.032-.176.048-.272.08-3.52 1.136-5.392 4.24-5.552 4.496a6.755 6.755 0 0 1-1.76-4.56ZM8 14.832a6.813 6.813 0 0 1-4.192-1.44c.128-.256 1.504-2.928 5.36-4.272.016-.016.032-.016.048-.016.96 2.496 1.36 4.576 1.456 5.184A6.758 6.758 0 0 1 8 14.832Zm3.808-1.168c-.064-.416-.432-2.416-1.328-4.864 2.144-.336 4.016.224 4.256.288a6.79 6.79 0 0 1-2.928 4.576Z"
        fill={color}
      />
    </svg>
  );
};

export { DribbbleIcon };
