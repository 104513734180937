function repeatRaf(func) {
  let cleared;
  const run = () => {
    func();
    if (!cleared) {
      requestAnimationFrame(run);
    }
  };
  requestAnimationFrame(run);
  // Cleared callback.
  return () => true;
}

function repeatInIntervals(func, delay = 60) {
  const interval = setInterval(func, delay);
  return clearInterval.bind(null, interval);
}

/**
 * Repeatedly call a function using requestAnimationFrame or fallback to setInterval
 * @param  {Function} func     Function to run
 * @param  {number}   delay    Delay between calls (if using setInterval)
 * @return {Function}          Function to end cycle
 */
export function repeatedly(func, delay) {
  return typeof window !== 'undefined' && window.requestAnimationFrame
    ? repeatRaf(func)
    : repeatInIntervals(func, delay);
}
